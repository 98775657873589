import React, { useState, useEffect } from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CInputGroup,
    CRow,
    CSpinner

} from "@coreui/react";
import Select from "react-select";
import * as icon from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilPeople, cilTrash } from "@coreui/icons";
import strings from "../strings";
import DataTable from "react-data-table-component";
import { string } from "prop-types";
import { Link } from "react-router-dom";
import { SubscriptionUsers, getSubList } from "../Helper/Index.Api";
import swal from 'sweetalert';
import AppTitle from '../AppTitle';
const moment = require('moment');


const SubscribedUsers = () => {
    const [data, setData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    const [searchbyName, setSearchByName] = useState("");
    const [page, setPage] = useState(1);
    const [userId, setUserId] = useState({ value: "", label: "Select User" });
    const [users, setUsers] = useState([]);
    const [Options, setOptins] = useState([]);


    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    useEffect(() => {
        handlePerRowChange(1)
    }, []);

    useEffect(() => {
        // subscription users
        SubscriptionUsers().then((res) => {
            setUsers(res.data)
            return res;
        }).then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.first_Name + " " + el.last_Name + " "
                        }(${el?.email
                        })`
                }
            });
            setOptins(selectOptins)
        }).catch(err => console.log(err))

    }, [])

    // row change
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        setListLoading(true);
        getSubList(ofs, "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => page * 10 + index + 1 - 10,
            width: "70px"
        },
        {
            name: 'Avtar',
            selector: row => (
                <>
                    <div className="">
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            src={
                                row?.userDetails[0]?.userAvatar?.cdnlocation ? strings?.MEDIAPATH + row?.userDetails[0]?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            } />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'User Name',
            selector: row => (`${row?.userDetails[0]?.first_Name
                } ${row?.userDetails[0]?.last_Name
                }`) + " " + `(${row?.userDetails[0]?.email})`,
            // width:"240px"
        },
        {
            name: 'Subscribed Plan',
            selector: row => (row?.planDetails[0]?.title),
            width: "120px"
        }, {
            name: 'Purchase Date',
            selector: row => (
                <> {
                    moment(row?.entryDate).format("DD MMMM YYYY")
                } </>
            ),
            // width:"200px"
        }, {
            name: 'Expiry Date',
            selector: row => (
                <> {
                    row?.expDate ? (moment(row?.expDate).format("DD MMMM YYYY")) : <div style={
                        { marginLeft: "60px" }
                    }>-</div>
                } </>
            ),
            // width:"200px"
        },
        {
            name: 'Plan Type',
            selector: row => (
                <>
                    {console.log("row:", row)}
                    <p>{row?.planType}</p>
                </>
            ),
            width: "100px"
        },
        {
            name: "Created At",
            selector: row => <div>{moment(row.createdAt).format("MMM DD YYYY")}</div>
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Link to={
                        `/subscribedusers/${row?._id
                        }`
                    }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                icon.cilFolderOpen
                            }
                                size="lg" />
                        </CButton>
                    </Link>
                </>
            ),
            width: "100px"
        },
    ];

    // pagination
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 1;
        }
        setPage(page)
        var ofs = (page - 1) * 10;
        getSubList(ofs, userId.value).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

// search functionality
    const handleSearch = (e) => {
        e.preventDefault();
        setListLoading(true);
        getSubList(0, userId.value).then((res) => {
            setListLoading(false);
            setData(res.data);
            if (res?.err === 200) {
                setTotalRows(res.totalCount);
            }
            setListLoading(false)
        }).catch((err) => {
            console.log(err)
            setListLoading(false)
        })

    }

    // serch inputs clear
    const handleClear = () => {
        handlePerRowChange(1);
        setUserId({ value: "", label: "Select User" });
    }


    return (
        <>
            <AppTitle title={"Subscribed Users"} />
            <div className='px-lg-5 mt-lg-5 px-2 mt-2'>
                <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        {/* <CFormSelect size="md"
                                            style={
                                                {fontSize: "14px"}
                                            }
                                            value={
                                                userId || ""
                                            }
                                            onChange={
                                                (e) => {
                                                    setUserId(e.target.value)
                                                }
                                            }
                                            className="mb-3"
                                            aria-label="Large select example">
                                            <option style={
                                                    {fontSize: "14px"}
                                                }
                                                value={""}>Select user</option>
                                            {
                                            users ?. map((el) => {
                                                return <option style={
                                                        {fontSize: "14px"}
                                                    }
                                                    value={
                                                        el._id + ""
                                                }>
                                                    {
                                                    el ?. first_Name + " " + el ?. last_Name + " "
                                                }
                                                    <span style={
                                                        {marginLeft: "20px"}
                                                    }>({
                                                        "  " + el.email
                                                    })</span>
                                                </option>
                                        })
                                        } </CFormSelect> */}
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={userId}
                                            onChange={
                                                (value) => {
                                                    setUserId(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol xs={12}
                                        className="mt-4">
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    (e) => handleSearch(e)
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CCard>
                    <CCardHeader>
                        <strong>Subscribed Users</strong>
                    </CCardHeader>
                    <CCardBody> {
                        listLoading ? <div className='d-flex justify-content-center'><CSpinner style={
                            { marginTop: "40px" }
                        }
                            color='info' /></div> : <DataTable columns={columns}
                                data={data}
                                style={
                                    { border: '1px solid black' }
                                }
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={
                                    []
                                }
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange} />
                    } </CCardBody>
                </CCard>

            </div>
        </>
    )
}

export default SubscribedUsers;
