import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CRow,
    CSpinner
} from "@coreui/react";
import * as icon from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { cilFolderOpen } from "@coreui/icons";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllEvents, findEvent } from "../Helper/Index.Api";
import AppTitle from "../AppTitle";
import Select from "react-select";
import moment from "moment";

const EventList = () => {
    // const [events, setEvents] = useState([]);
    const [listLoading, setListLoading] = useState(true);
    const [threads, setThreads] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [totalRows, setTotalRows] = useState();
    const [userId, setUserId] = useState({ value: "", label: "Search User" });
    const [page, setPage] = useState(1);
    const [Options, setOptins] = useState([]);

     // get events
     useEffect(() => {
        getAllEvents().then((res) => {
            setUsers(res.data);
            return res;
        }).then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el.email, label: `${el.first_name + " " + el.last_name + " "
                        }(${el?.email
                        })`
                }
            });
            setOptins(selectOptins)
        }).catch(err => console.log(err))
    }, [])

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };
    useEffect(() => {
        handlePerRowChange(1)
    }, []);

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Event',
            selector: row => (
                <>
                    <div className="">
                        <img className=" rounded-circle"
                            style={{ width: "40px", height: "40px", objectFit: "contain" }}
                            src={
                                row?.event_image?.location
                                    ? row?.event_image?.location
                                    : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            }
                        />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Title',
            selector: row => (row?.title ? (row?.title?.length > 50 ? row?.title?.slice(0, 47) + "..." : row?.title) : "-"),
        },
        {
            name: 'Date',
            selector: row => (row?.start_time ? moment(row.start_time).format("MMM DD YYYY") : "-"),
        },
        {
            name: 'Status',
            selector: row => (row?.approve_status ? (row?.approve_status > 50 ? row?.approve_status?.slice(0, 47) + "..." : row?.approve_status) : "-"),
        },
        {
            name: 'Created By',
            selector: row => (row?.first_name + " " + row?.last_name) + ` (${row?.email})`,
        },
        {
            name: "Created At",
            selector: row => <div>{moment(row.createdAt).format("MMM DD YYYY")}</div>
        },
        {
            name: 'Actions',
            selector: row => (
                <>
                    <Link to={`/events/${row?._id}`} className="p-1">
                        <CButton color="secondary" title="View Event details" variant="outline" className="ps-1 px-1">
                            <CIcon icon={icon.cilFolderOpen} size="lg" />
                        </CButton>
                    </Link>
                </>
            ),
            width: "80px"
        },
    ];
       // row change functionality
       const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        setPage(page)
        var ofs = (page - 1) * 10;
        setListLoading(true);
        findEvent(ofs, "", "", "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setThreads(res.data);
                setTotalRows(res.total);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };
    

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };
    // search functionality
    const handleSearch = () => {
        setListLoading(true);
        findEvent(0, searchTitle, userId.value).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setThreads(res.data);
                setTotalRows(res.total);
            }
        })
    }

    // pagination
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 1;
        }
        setPage(page)
        var ofs = (page - 1) * 10;

        findEvent(ofs, searchTitle, userId.value).then((res) => {
            setListLoading(false);
            if (res.err === 200) {

                setThreads(res.data);
                setTotalRows(res.total);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    // input clear functionality
    const handleClear = () => {
        handlePerRowChange(1);
        setSearchTitle("");
        setUserId({ value: "", label: "Search User" })
    }

    return (
        <>
            <AppTitle title={"Events"} />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
            {/* filer start */}
            <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>Title</CFormLabel>
                                        <input type="text" placeholder="Search Title"
                                            value={
                                                searchTitle || ""
                                            }
                                            onChange={
                                                (e) => {
                                                    setSearchTitle(e.target.value)
                                                }
                                            }
                                            className="form-control" />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={userId}
                                            onChange={
                                                (value) => {
                                                    setUserId(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol xs={12}
                                        className="mt-4">
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    () => handleSearch()
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>

                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>

            {/* filter end */}
            <CCard>
                    <CCardHeader>
                        <strong>Events</strong>
                    </CCardHeader>
                    {console.log("TOTALROWS:", totalRows)}
                    <CCardBody> {
                        listLoading ? <div className="d-flex justify-content-center"><CSpinner style={
                            { marginTop: "40px" }
                        }
                            color="info" /></div> : <DataTable columns={columns}
                                data={threads}
                                style={
                                    { border: '1px solid black' }
                                }
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={
                                    []
                                }
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange} />
                    } </CCardBody>
                </CCard>
            </div>
        </>
    );
};

export default EventList;