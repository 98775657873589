import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CRow,
    CSpinner
} from "@coreui/react";
import * as icon from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getAllAttendees } from "../Helper/Index.Api";
import AppTitle from "../AppTitle";
import { useParams } from 'react-router-dom';

const EventBooking = () => {
    const [bookings, setBookings] = useState([]);
    const [listLoading, setListLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        getAllAttendees(id).then((res) => {
            console.log(res.data);
            setBookings(res.data);
            setListLoading(false);
        }).catch(err => {
            console.log(err);
            setListLoading(false);
        });
    }, []);

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => index + 1,
            width: "70px"
        },
        {
            name: 'Name',
            selector: row => (row?.first_name + " " + row?.last_name),
        }, 
        {
            name: 'Phone',
            selector: row => (row?.phone),
        },
        {
            name: 'Email',
            selector: row => (row?.phone),
        },
        {
            name: 'No of tickets',
            selector: row => (row?.no_of_tickets),
        },
        {
            name: 'Amount',
            selector: row => (row?.amount),
        },
        {
            name: "Payment Status",
            selector: row => row?.payment_status ,
        },
        {
            name: 'Actions',
            selector: row => (
                <>
                    <Link to={`/events/${row?._id}`} className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={icon.cilFolderOpen} size="lg" />
                        </CButton>
                    </Link>
                </>
            ),
            width: "80px"
        },
    ];

    return (
        <>
            <AppTitle title={"Bookings"} showbtn={true} path="/events" />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CCard>
                    <CCardHeader>
                        <strong>Bookings</strong>
                    </CCardHeader>
                    <CCardBody>
                        {listLoading ? (
                            <div className="d-flex justify-content-center">
                                <CSpinner style={{ marginTop: "40px" }} color="info" />
                            </div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={bookings}
                                customStyles={customStyles}
                                highlightOnHover
                            />
                        )}
                    </CCardBody>
                </CCard>
            </div>
        </>
    );
};

export default EventBooking;