import {
    CAvatar,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CContainer,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CRow,
    CSpinner
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getFeedbackDetails, updateFeedback } from '../Helper/Index.Api'
import swal from 'sweetalert'
import AppTitle from '../AppTitle'
import strings from '../strings'


export const FeedbackDetails = () => {
    const navigate = useNavigate()
    const [avatar, setAvatar] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [enquiryDate, setEnquiryDate] = useState("")
    const [email, setEmail] = useState("")
    const [phonenumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("")
    const [replyBy, setReplyBy] = useState("")
    const { id } = useParams()
    const [feedback, setFeedback] = useState("");
    const [suggestion, setSuggestion] = useState("");
    const [loading, isLoading] = useState(false);
    const [state, setState] = useState("");
    const [reply, setReply] = useState("");
    const [showError, setShowError] = useState(false);
    const [feedbackReply, setFeedbackReply] = useState(false);


    // updating reply by user and sending email to the user who created feedback
    const handleSubmit = () => {
        if (feedback.length <= 0) {
            setFeedbackReply(true)
            return
        }
        if (showError) {
            return;
        }

        let payload = {
            id: id,
            reply: feedback,
            reply_by: localStorage.getItem("_id")
        }

        updateFeedback(payload).then((res) => {
            if (res.err === 200) {
                swal("Feedback", "Feedback successfully added", "success").then(() => navigate("/feedback"))
            }
        }).catch(err => console.log(err))

    }

    // handle change
    const handleChange = (e) => {
        if (e.target.value.length < 20) {
            setShowError(true)
        } else {
            setShowError(false)
        }
        setFeedback(e.target.value);
        setFeedbackReply(false);
    };

    // get feedback details
    useEffect(() => {
        isLoading(true)
        getFeedbackDetails(id).then((res) => {
            setAvatar(res.feedback.userId.userAvatar?.cdnlocation)
            setFirstName(res.feedback.userId.first_Name)
            setLastName(res.feedback.userId.last_Name)
            setEmail(res.feedback.userId.email)
            setPhoneNumber(res.feedback.userId.phonenumber)
            setCountry(res.feedback.userId.country);
            setSuggestion(res.feedback.suggestion);
            setState(res.feedback.userId.city)
            setReply(res.feedback.reply)
            isLoading(false)
        }).catch(err => {
            console.log(err);
            isLoading(false)
        })

    }, [])


    return (
        <>
            <AppTitle title={"Feedback Details"}
                showbtn={true}
                path={"/feedback"} /> {
                loading ? (
                    <div className='d-flex justify-content-center'><CSpinner style={
                        { marginTop: "40px" }
                    }
                        color='info' /></div>
                ) : (
                    <div>
                        <div className='container w-100 px-lg-5 mt-lg-5 px-2 mt-2'>
                            <CRow>
                                <CCol xs={12}
                                    md={12}
                                    lg={12}
                                    sm={12}>
                                    <CCard className="mt-4"
                                        style={
                                            { borderRadius: "5px 5px 0px 0px" }
                                        }>
                                        <CRow>
                                            <CCol sm={12}>
                                                <div style={
                                                    {
                                                        backgroundColor: "rgb(79 113 189 / 39%)",
                                                        height: "150px",
                                                        borderRadius: "5px 5px 0px 0px"
                                                    }
                                                }></div>
                                                <div style={
                                                    {
                                                        position: "absolute",
                                                        zIndex: 1,
                                                        top: "75px",
                                                        left: "25px"
                                                    }
                                                }>
                                                    <img src={
                                                        avatar ? strings.MEDIAPATH + avatar : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                    }
                                                        style={
                                                            {
                                                                width: "150px",
                                                                height: "150px"
                                                            }
                                                        }
                                                        className='rounded-circle border border-white border-5'
                                                        alt="" />

                                                    <div className='ms-1'>
                                                        <h4> {
                                                            firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
                                                        }
                                                            {" "}
                                                            {lastName} </h4>
                                                        <small className='pb-3'
                                                            style={
                                                                { color: "black" }
                                                            }>
                                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                            {"    "}
                                                            {" "}
                                                            {state}
                                                            {"    "}
                                                            {" "}
                                                            {country}
                                                            <br />
                                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                            {"    "}
                                                            {" "}
                                                            {email}
                                                            <br />
                                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                                            {"    "}
                                                            {" "}
                                                            {phonenumber} </small>
                                                    </div>
                                                </div>
                                                <div style={
                                                    {
                                                        backgroundColor: "white",
                                                        height: "250px",
                                                        borderRadius: " 0px 0px 0px 0px"
                                                    }
                                                }></div>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                    <CCard className='mt-3 px-2 px-md-5 py-2'
                                        style={
                                            { borderRadius: "5px 5px 0px 0px" }
                                        }>
                                        <CFormLabel>Name :</CFormLabel>
                                        <div className='border border-1 rounded px-4 py-2'>
                                            {firstName}
                                            {" "}
                                            {lastName} </div>
                                        <br />
                                        <CFormLabel>Email :</CFormLabel>
                                        <div className='border border-1 rounded px-4 py-2'>
                                            {email}</div>
                                        <br />
                                        <CFormLabel>Suggestion :</CFormLabel>
                                        <div className='border border-1 rounded px-4 py-2'
                                            style={
                                                {
                                                    color: "#5470af",
                                                    fontSize: "18px",
                                                    fontWeight: 600
                                                }
                                            }>
                                            {suggestion}</div>
                                        <br />
                                        <CFormLabel>Reply:</CFormLabel>
                                        {reply === "" && <div className='border border-0 rounded'>
                                            <textarea className='border border-1 rounded px-4 py-2 w-100' rows={6} onChange={handleChange} />
                                            {!feedbackReply && showError && <lable style={{ fontSize: "14px", color: "red" }}>Minimum 20 characters required.</lable>}
                                            {feedbackReply && < lable style={{ color: "red", fontSize: "14px" }}>Please enter feedback reply</lable>}
                                        </div>}
                                        {reply !== "" && <div className='border border-1 rounded px-4 py-2'>
                                            {reply}
                                        </div>}
                                        <br />
                                        {
                                            reply === "" && <CButton
                                                onClick={handleSubmit}
                                                style={
                                                    {
                                                        width: "150px",
                                                        backgroundColor: "#5470af"
                                                    }
                                                }>Submit</CButton>
                                        }
                                    </CCard>
                                </CCol>
                            </CRow>
                        </div>
                    </div >
                )
            } </>
    )
}
