import React, { useEffect, useState } from 'react';
import AppTitle from '../AppTitle';
import { CButton, CCard, CCol, CRow, CSpinner } from '@coreui/react';
import { EventDetail, approveEvent, deleteEvent } from "../Helper/Index.Api";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import "./postdetails.css";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

export const EventDetails = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        EventDetail(id).then((res) => {
            setData(res.data);
            setLoading(false);
        }).catch(err => console.log(err));
    }, [id]);

    // approve event
    const handleSubmit = () => {
        approveEvent(id).then((res) => {
            if (res.statusCode === 200) {
                swal("Event", res.msg, "success");
            } else if (res.statusCode === 404) {
                swal("Event", res.msg, "error");
            }
        }).catch(err => console.log(err));
    };

    // delete event
    const handleDelete = () => {
        deleteEvent(id).then((res) => {
            if (res.statusCode === 200) {
                swal("Event", res.msg, "success");
            } else if (res.statusCode === 404) {
                swal("Event", res.msg, "error");
            }
            navigate('/events');
        }).catch(err => console.log(err));
    };

    const { event_image = {}, description = "", title = "", first_name = "", middle_name = "", last_name = "" } = data;

    return (
        <>
            <AppTitle title="Event Details" showbtn={true} path="/events" />
            <div className='px-lg-5 mt-lg-5 px-sm-2 mt-2 mb-4'>
                {!loading ? (
                    <CRow>
                        <CCol md={12} lg={12} sm={12}>
                            <CCard className='px-lg-4 px-2 py-lg-4 py-2'>
                                <div className='carousel_div_display'>
                                    <div style={{ borderRight: "1px solid #80808050" }} className='carousel-width'>
                                        <div className='px-4'>
                                            <div style={{ marginBottom: "1rem" }}>
                                                <h4>{title}</h4>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <img
                                                    src={event_image.location || "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"}
                                                    style={{ width: "100%", height: "auto" }}
                                                    className='border border-white border-5'
                                                    alt=""
                                                />
                                            </div> <br />
                                            <div className='px-lg-4 px-md-1'>{description}</div> <br />
                                            <div className='px-lg-4 px-md-1' style={{ fontSize: "14px" }}>Organizer: {`${first_name} ${middle_name} ${last_name}`}</div> <br />
                                            <div className='px-lg-4 px-md-1'>
                                                <div className="d-flex align-items-center gap-2">
                                                    {/* Conditionally render Approve Event button */}
                                                    {data?.approve_status === "Pending" && (
                                                        <CButton
                                                            style={{
                                                                backgroundColor: "#5470af",
                                                                border: "none"
                                                            }}
                                                            className="m-2 text-white"
                                                            onClick={() => handleSubmit()}
                                                            type="button"
                                                        >
                                                            Approve Event
                                                        </CButton>
                                                    )}
                                                    <CButton color="danger" onClick={handleDelete}>Delete Event</CButton>
                                                    <Link to={`/bookings/${id}`}>
                                                        <CButton color="primary">Check Bookings</CButton>
                                                    </Link>
                                                    <Link to={`/transactions/${id}`}>
                                                        <CButton color="warning">Check Transactions</CButton>
                                                    </Link>
                                                </div> <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4 comments-width px-lg-4 px-md-1' style={{ marginTop: "10px" }}>
                                        <h5>Event Details</h5>
                                        <div className='event-details'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Title:</th>
                                                        <td>{data?.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Approval Status:</th>
                                                        <td>{data?.approve_status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address:</th>
                                                        <td>{data?.address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Event Time:</th>
                                                        <td>{moment(data?.start_time).format("MMMM D YYYY H:mm")}-{moment(data?.end_time).format("MMMM D YYYY H:mm")}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Event Fee:</th>
                                                        <td>{data?.event_fee}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Currency:</th>
                                                        <td>{data?.currency}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>No of Tickets:</th>
                                                        <td>{data?.no_of_tickets}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5>Organizer Details</h5>
                                        <div className='event-details'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Name:</th>
                                                        <td>{data?.first_name} {data?.middle_name} {data?.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email:</th>
                                                        <td>{data?.email}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <h5 className='mt-4'>Bank Details</h5>
                                        <div className='bank-details'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Bank Name:</th>
                                                        <td>{data?.bank_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Account No:</th>
                                                        <td>{data?.account_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>IFSC Code:</th>
                                                        <td>{data?.code}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <h5 className='mt-4'>Ticket Details</h5>
                                        <div className='ticket-details'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Total Earnings:</th>
                                                        <td>{data?.total_earnings}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Create Date:</th>
                                                        <td>{moment(data?.createdAt).format("MMM DD YYYY")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </CCard>
                        </CCol>
                    </CRow>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CSpinner color='info' />
                    </div>
                )}
            </div>
        </>
    );
};