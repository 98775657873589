import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CFormSelect,
    CRow,
    CSpinner
} from "@coreui/react";
import * as icon from '@coreui/icons';
import swal from "sweetalert";
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilPeople, cilTrash } from "@coreui/icons";
import strings from "../strings";
import DataTable from "react-data-table-component";
import { string } from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { EnquiryUsers, getAllEnquiries } from "../Helper/Index.Api";
import AppTitle from "../AppTitle";
import Select from "react-select";
import moment from "moment";


const Enquiries = () => {
    const [data, setData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    const [searchbyName, setSearchByName] = useState("");
    const [searchbyEmail, setSearchByEmail] = useState("");
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState({ value: "", label: "Select User" });
    const [Options, setOptins] = useState([])

    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate()

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };


    useEffect(() => {
        handlePerRowChange(1);

        // get enquiry lists
        EnquiryUsers().then((res) => {
            if (res.err === 200) {
                setUsers(res.data)
            }
            return res;
        }).then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.first_Name + " " + el.last_Name + " "
                        }(${el?.email
                        })`
                }
            });
            setOptins(selectOptins)
        }).catch((error) => console.log(error))
    }, []);

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => page * 10 + index + 1 - 10,
            width: "70px"
        },
        {
            name: 'Avtar',
            selector: row => (
                <> {
                    console.log("row:", row)
                }
                    <div className="">
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            src={
                                row?.userId?.userAvatar?.cdnlocation ? strings.MEDIAPATH + row?.userId?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            } />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Name',
            selector: row => (`${row?.userId?.first_Name
                } ${row?.userId?.last_Name
                }`),
            // width: "300px"
        },
        {
            name: 'Email',
            selector: row => (row?.email),
            // width: "250px"
        }, {
            name: 'Subject',
            selector: row => (row?.subject?.length > 40 ? row?.subject?.slice(0, 40) : row?.subject),
            // width: "300px"
        },
        {
            name: 'Message',
            selector: row => (row?.message?.length > 40 ? row?.message?.slice(0, 40) + "..." : row?.message)
        },
        {
            name: "Created At",
            selector: row => <div>{moment(row?.createdAt).format("MMM DD YYYY")}</div>
        },
        {
            name: 'Actions',
            selector: row => (
                <>
                    <Link to={
                        `/enquiries/${row?._id
                        }`
                    }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                icon.cilFolderOpen
                            }
                                size="lg" />
                        </CButton>

                    </Link>
                </>
            ),
            width: "80px"
        },
    ];

    // row change for datatable
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        setListLoading(true);
        getAllEnquiries(ofs, "", "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };

    // pagination for datatable
    const handlePageChange = (page) => {

        if (page === undefined) {
            page = 1;
        }
        setPage(page)
        var ofs = (page - 1) * 10;

        getAllEnquiries(ofs, userId.value, searchbyEmail).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    // search functionality
    const handleSearch = () => {
        setListLoading(true);
        getAllEnquiries(0, userId.value, searchbyEmail).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.data.length);
            }
        })
    }

    // clear search box functionality
    const handleClear = () => {
        handlePerRowChange(1);
        setSearchByName("");
        setSearchByEmail("");
        setUserId({ value: "", label: "Select User" })
    }

    return (
        <>
            <AppTitle title={"Enquiries"} />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>

                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        {/* <CFormSelect size="md"
                                            style={
                                                {fontSize: "16px"}
                                            }
                                            value={
                                                userId || ""
                                            }
                                            onChange={
                                                (e) => {
                                                    setUserId(e.target.value)
                                                }
                                            }
                                            className="mb-3"
                                            aria-label="Large select example">
                                            <option style={
                                                    {fontSize: "14px"}
                                                }
                                                value={""}>Select user</option>
                                            {
                                            users ?. map((el) => {
                                                return <option style={
                                                        {fontSize: "14px"}
                                                    }
                                                    value={
                                                        el._id + ""
                                                }>
                                                    {
                                                    el ?. first_Name + " " + el ?. last_Name + " "
                                                }
                                                    <span style={
                                                        {marginLeft: "20px"}
                                                    }>({
                                                        "  " + el.email
                                                    })</span>
                                                </option>
                                        })
                                        } </CFormSelect> */}
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={userId}
                                            onChange={
                                                (value) => {
                                                    setUserId(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Email</CFormLabel>
                                        <input type="text" placeholder="Search Email"
                                            value={
                                                searchbyEmail || ""
                                            }
                                            onChange={
                                                (e) => {
                                                    setSearchByEmail(e.target.value)
                                                }
                                            }
                                            className="form-control" />
                                    </CCol>

                                    <CCol xs={12}
                                        className="mt-4">
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    () => handleSearch()
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>

                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>

                <CCard>
                    <CCardHeader>
                        <strong>Enquiry List</strong>
                    </CCardHeader>
                    <CCardBody> {
                        listLoading ? <div className="d-flex justify-content-center"><CSpinner style={
                            { marginTop: "40px" }
                        }
                            color="info" /></div> : <DataTable columns={columns}
                                data={data}
                                style={
                                    { border: '1px solid black' }
                                }
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={
                                    []
                                }
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange} />
                    } </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default Enquiries;
