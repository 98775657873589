import React, { useEffect, useState } from 'react'
import AppTitle from '../AppTitle'
import { useParams } from 'react-router-dom';
import { CommunityPageDetails, GetCommunityPagePosts, ConnectedIndians } from "../Helper/Index.Api";
import { CCard, CCol, CNav, CNavItem, CNavLink, CRow, CSpinner } from '@coreui/react';
import { FaGlobe } from "react-icons/fa"
import { AiFillExclamationCircle } from "react-icons/ai"
import { AiFillEdit } from "react-icons/ai"
import CommunityPagePosts from "./CommunityPaegPosts"
import CommunityPageFollowers from "./CommunityPageFollowrs"
import CIcon from '@coreui/icons-react';
import { cilUser } from '@coreui/icons';
import { BiSolidUserCircle } from "react-icons/bi"
import strings from '../strings';

const CommunityPageDetailsPage = () => {
    const [data, setData] = useState({});
    const [userposts, setuserposts] = useState([]);
    const [postPage, setPostPage] = useState(1);
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    // community page details 
    useEffect(() => {
        setLoading(true)
        CommunityPageDetails(id).then((res) => {
            setData(res.data)
        }).catch((err) => console.log(err))

        GetCommunityPagePosts(id).then((res) => {
            setuserposts(res?.data)
            setLoading(false)
        }).catch((error) => console.log(error));
    }, [])

    // community page connected indians
    useEffect(() => {
        setLoading(true)
        ConnectedIndians(id, postPage).then((res) => {
            setFollowers(res.data);
            setLoading(false)
        }).catch(error => console.log(error))
    }, [postPage]);



    return (
        <>
            {loading ? (<div className='text-center mt-5'>
                <CSpinner color='info' />
            </div>) : (<div>
                <AppTitle title={"Community Page Details"}
                    showbtn={true}
                    path={"/communitypages"} />
                <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                    <CRow>
                        <CCol md={12}
                            lg={12}
                            sm={12}>
                            <CCard style={
                                { borderRadius: "5px 5px 0px 0px" }
                            }>
                                <CRow>
                                    <CCol sm={12}>
                                        <div style={
                                            {
                                                backgroundColor: "rgb(79 113 189 / 39%)",
                                                height: "150px",
                                                borderRadius: "5px 5px 0px 0px"
                                            }
                                        }></div>
                                        <div style={
                                            {
                                                position: "absolute",
                                                zIndex: 1,
                                                top: "75px",
                                                left: "25px"
                                            }
                                        }>
                                            <img src={
                                                data?.logoData?.cdnlocation ? strings.MEDIAPATH + data?.logoData?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                            }
                                                style={
                                                    {
                                                        width: "150px",
                                                        height: "150px"
                                                    }
                                                }
                                                className='rounded-circle border border-white border-5'
                                                alt="" />

                                            <div className='ms-1'>
                                                <h4>{
                                                    data?.title
                                                }</h4>
                                                <small className='pb-3'
                                                    style={
                                                        { color: "black" }
                                                    }>
                                                    <>
                                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        {" "}
                                                        {
                                                            data?.createdBy?.first_Name + " " + data?.createdBy?.last_Name
                                                        }
                                                        <br />
                                                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                        {"  "}
                                                        {
                                                            data?.region
                                                        }, {" "}
                                                        {
                                                            data?.city
                                                        }, {" "}
                                                        {
                                                            data?.countryId?.countryName
                                                        }<br /></>
                                                    {
                                                        data?.websitelink != "" && <><FaGlobe />{" "}
                                                            {
                                                                data?.websitelink
                                                            }<br />
                                                        </>
                                                    }
                                                    {
                                                        data?.catchline !== "" && <><AiFillEdit />{" "}
                                                            {
                                                                data?.catchline
                                                            }<br /></>
                                                    }
                                                    {
                                                        data?.about !== "" && <><AiFillExclamationCircle />{" "}
                                                            {
                                                                data?.about
                                                            }</>
                                                    } </small>
                                            </div>
                                        </div>
                                        <div style={
                                            {
                                                backgroundColor: "white",
                                                height: "280px",
                                                borderRadius: " 0px 0px 0px 0px"
                                            }
                                        }></div>
                                    </CCol>
                                </CRow>
                                <div style={
                                    {
                                        backgroundColor: "white",
                                        marginTop: "20px",
                                        borderRadius: " 0px 0px 0px 0px"
                                    }
                                }>
                                    <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist"
                                        style={
                                            {
                                                position: "absolute",
                                                bottom: "17px",
                                                left: "23px"
                                            }
                                        }>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link custom-button active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                                Posts
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link custom-button" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                                {
                                                    followers.length
                                                }
                                                {" "}
                                                Followers
                                            </button>
                                        </li> */}
                                        <hr />
                                    </ul>
                                </div>
                                <div className='px-4'>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink active
                                                id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"
                                            >
                                                <span style={{ fontSize: "18px", fontWeight: "700" }}>{" "}{userposts.length}</span>{" "}
                                                Posts
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem >
                                            <CNavLink
                                                id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
                                            ><span style={{ fontSize: "18px", fontWeight: "700" }}>{followers.length}</span>{" "}  Followings</CNavLink>
                                        </CNavItem>
                                    </CNav>
                                </div>
                            </CCard>
                        </CCol>
                    </CRow>
                </div>

                <div className="px-lg-5 mt-lg-3 px-2 mt-2">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <CommunityPagePosts userPostData={userposts} />
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <CommunityPageFollowers followersData={followers} />
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    )
}

export default CommunityPageDetailsPage;
