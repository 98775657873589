import React, { useState, useEffect } from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CInputGroup,
    CInputGroupText,
    CRow,
    CSpinner

} from "@coreui/react";
import * as icon from '@coreui/icons';
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilPeople, cilTrash } from "@coreui/icons";
import strings from "../strings";
import DataTable from "react-data-table-component";
import { string } from "prop-types";
import { Link } from "react-router-dom";
import { ReportPostUsers, getReportedPosts } from "../Helper/Index.Api";
import swal from 'sweetalert';
import AppTitle from '../AppTitle';
import Select from "react-select";
import moment from "moment"

const ReportedPost = () => {
    const [data, setData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    const [searchbyName, setSearchByName] = useState("");
    const [searchbypost, setSearchBypost] = useState("");
    const [filterStartDate, setfilterStartDate] = useState("");
    const [filterEndDate, setfilterEndDate] = useState("");
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState({ value: "", label: "Select user" });
    const [Options, setOptions] = useState([]);

    // column style
    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    let createdStyles = {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        textAlign: "center"
    }

    useEffect(() => {
        handlePerRowChange(1);
        // reported post users
        ReportPostUsers().then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.first_Name + " " + el.last_Name + " "
                        }(${el?.email
                        })`
                }
            });
            setOptions(selectOptins)
        }).catch((err) => console.log(err))
    }, []);

    // reported post columns
    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => page * 10 + index + 1 - 10,
            width: "70px"
        },
        {
            name: 'Avtar',
            selector: row => (
                <>
                    <div className="" style={createdStyles}>
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            src={
                                row?.createdBy?.userAvatar?.cdnlocation ? strings?.MEDIAPATH + row?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            } />{" "}
                        <p className='text'>
                            {/* {row?.createdBy?.first_Name + " " + row?.createdBy?.last_Name}{" "}{`(${row?.createdBy?.email})`} */}
                            {(row?.createdBy?.first_Name + " " + row?.createdBy?.last_Name + " " + row?.createdBy?.email).length > 40 ? (row?.createdBy?.first_Name + " " + row?.createdBy?.last_Name + " " + row?.createdBy?.email).substring(0, 37)+"..." : (row?.createdBy?.first_Name + " " + row?.createdBy?.last_Name + " " + `(${row?.createdBy?.email})`)}
                        </p>
                    </div>
                </>
            ),
            // width: "75px"
        },
        {
            name: 'Post',
            selector: row => (
                <>
                    <div className="">
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            // src={row?.mediaFiles.length > 0 ? strings?.MEDIAPATH + row?.mediaFiles[0].cdnlocation : "https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"}
                            src={
                                row?.thumbNail?.cdnlocation ? strings?.MEDIAPATH + row?.thumbNail?.cdnlocation : "https://static.vecteezy.com/system/resources/previews/004/141/669/original/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
                            }
                        />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Message',
            selector: row => (row?.message ? ((row?.message.length > 50) ? (row?.message.slice(0, 50) + "...") : (row?.message)) : ("-")),
            // width: "200px"
        },
        {
            name: 'Report Count',
            selector: row => (row?.TotalReports > 0 ? row?.TotalReports : 0),
            width: "120px"
        },
        {
            name: "Post Created At",
            selector: row => <div>{moment(row?.createdAt).format("MMM DD YYYY")}</div>,
            width: "120px"
        },
        {
            name: 'View Post',
            selector: row => (
                <>
                    <Link to={
                        `/view/reportedpost/${row?._id
                        }/${row?.createdBy?._id
                        }`
                    }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                icon.cilFolderOpen
                            }
                                size="lg" />
                        </CButton>
                    </Link>
                </>
            ),
            width: "80px"
        },
    ];

// row per change
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }

        var ofs = (page - 1) * 10;
        setListLoading(true);
        getReportedPosts(ofs, "", "", "", "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                console.log("res.data:", res.data)
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };

    // pagination change
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 1;
        }
        setPage(page)
        var ofs = (page - 1) * 10;
        getReportedPosts(ofs, userId.value, searchbypost, filterStartDate, filterEndDate).then((res) => {
            setListLoading(true);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
                setListLoading(false);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    //search functionality
    const handleSearch = () => {
        setListLoading(true);
        getReportedPosts(0, userId.value, searchbypost, filterStartDate, filterEndDate).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            }
        })
    }

    // input clear functionality
    const handleClear = () => {
        handlePerRowChange(1);
        setUserId({ value: "", label: "Select user" });
        setfilterEndDate("");
        setSearchBypost("");
        setfilterStartDate("");
    }

    return (
        <>
            <AppTitle title={"Reported Posts"} />
            <div className='px-lg-5 mt-lg-5 px-2 mt-2'>
                <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={userId}
                                            onChange={
                                                (value) => {
                                                    setUserId(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>Report Start/End Date</CFormLabel>
                                        <CInputGroup className="mb-3">
                                            <CInputGroup className="mb-3">
                                                <CFormInput type="date"
                                                    value={
                                                        filterStartDate || ""
                                                    }
                                                    required
                                                    onChange={
                                                        (e) => {
                                                            if (filterEndDate && filterEndDate <= e.target.value) {
                                                                setfilterStartDate("");
                                                                swal("Warning", "Date should be less than selected end date", "warning")
                                                            }
                                                            else {
                                                                setfilterStartDate(e.target.value)
                                                            }
                                                        }
                                                    }
                                                    placeholder="Start Date" />
                                                <CInputGroupText>To</CInputGroupText>
                                                <CFormInput onChange={
                                                    (e) => {
                                                        if (filterStartDate <= e.target.value) {
                                                            setfilterEndDate(e.target.value);
                                                        }
                                                        else {
                                                            setfilterEndDate("");
                                                            swal("Warning", "Date should be greater than selected start date", "warning")
                                                        }
                                                    }
                                                }
                                                    type="date"
                                                    required
                                                    value={
                                                        filterEndDate || ""
                                                    }
                                                    placeholder="End Date" />
                                            </CInputGroup>
                                        </CInputGroup>
                                    </CCol>

                                    <CCol xs={12}>
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    () => handleSearch()
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CCard>
                    <CCardHeader>
                        <strong>Reported Posts</strong>
                    </CCardHeader>
                    <CCardBody> {
                        listLoading ? <div className='d-flex justify-content-center'><CSpinner color='info'
                            style={
                                { marginTop: "40px" }
                            } /></div> : <DataTable columns={columns}
                                data={data}
                                style={
                                    { border: '1px solid black' }
                                }
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={
                                    []
                                }
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange} />
                    } </CCardBody>
                </CCard>
            </div>
        </>
    )
}


export default ReportedPost;
