import React, { useState, useEffect } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CFormSelect,
    CRow,
    CSpinner

} from "@coreui/react";
import * as icon from '@coreui/icons';

import swal from "sweetalert";
import CIcon from "@coreui/icons-react";
import { cilFolderOpen, cilPeople, cilTrash } from "@coreui/icons";
import { deleteUser, getUniqueCountries, getUsers, getUsersAdmin, viewUser } from "../Helper/Index.Api";
import strings from "../strings";
import DataTable from "react-data-table-component";
import { string } from "prop-types";
import { Link, useSearchParams } from "react-router-dom";
import "../../components/Dashbord/dashbord.css"
import AppTitle from "../AppTitle";
import Select from "react-select";
import moment from "moment";

const Appusers = () => {
    const [data, setData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    const [page, setPage] = useState(1)
    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchPhone, setSearchPhone] = useState("");
    const [searchGender, setSearchGender] = useState({ value: "", label: "Select Gender" });
    const [searchCountry, setSearchCountry] = useState({ value: "", label: "Select Country" });
    const [contries, setCountries] = useState([""]);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState({ value: "", label: "Search User" });
    const [Options, setOptions] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const handleToggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const styleheader = {
        marginTop: "-24px",
        marginLeft: "-25px",
        marginRight: "-25px"
    }


    // getting unique country list
    function uniqueCountries() {
        getUniqueCountries().then((data) => {
            let selectOptins = data?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.countryName
                        }`
                }
            });
            setCountries(selectOptins)
        }).catch((error) => console.log("err:", error));
        getUsersAdmin()
            .then((res) => {
                let selectOptins = res?.data?.map((el) => {
                    return {
                        value: el._id, label: `${el.first_Name + " " + el.last_Name + " "
                            }(${el?.email
                            })`
                    }
                });
                setOptions(selectOptins)
            }).catch(err => console.log(err))
    }

    useEffect(() => {
        uniqueCountries()
        getUsersAdmin()
    }, []);

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };


    // deleting user
    const handleDeleteUser = (userid) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure to delete User?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancel", "Ok"]
        }).then((willDelete) => {
            if (willDelete) {
                setDeleteLoading(true);
                deleteUser(userid, "by-admin", "deleted By admin").then((res) => {
                    if (res.err === 200) {
                        swal("Success", res.msg, "success").then((ok) => {
                            if (ok) {
                                handlePerRowChange(1)
                                setDeleteLoading(false);
                            }
                        })

                    }
                })
            }
        })
    }

    useEffect(() => {
        handlePerRowChange(1)
    }, []);

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => page * 10 + index + 1 - 10,
            width: "70px"
        },
        {
            name: 'Avtar',
            selector: row => (
                <>
                    <div className="">
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            src={
                                row.userAvatar?.cdnlocation ? strings.MEDIAPATH + row.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            } />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Name',
            selector: row => (`${row.first_Name
                } ${row.last_Name
                }`)
        },
        {
            name: 'Email',
            selector: row => (`${row.email
                }`)
        }, {
            name: 'Phone Number',
            selector: row => (row.phonenumber),
            width: "120px"
        }, {
            name: 'City',
            selector: row => (row.city),
            // width: "150px"
        }, {
            name: 'District',
            selector: row => (row.district),
            // width: "150px"
        }, {
            name: 'State',
            selector: row => (row.state),
            // width: "150px"
        },
        {
            name: "Created At",
            selector: row => <div>{moment(row.createdAt).format("MMM DD YYYY")}</div>
        },
        {
            name: 'Actions',
            selector: row => (
                <>
                    <Link to={
                        `/viewuser/${row._id
                        }`
                    }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                icon.cilFolderOpen
                            }
                                size="lg" />
                        </CButton>

                    </Link>
                    <Link className="p-1">
                        <CButton disabled={deleteLoading} color="danger" title="Delete Order " variant="outline"
                            onClick={
                                () => handleDeleteUser(row._id)
                            }
                            className="px-1">
                            <CIcon icon={cilTrash}
                                size="lg" />
                        </CButton>
                    </Link>
                </>
            ),
            width: "120px"
        },
    ];


    // row change for data tables
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        var ofs = (page - 1) * 10;
        setListLoading(true);
        getUsers(ofs, "", "", "", "", "", "", "", "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };

    // pagination datatable
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 1;
        }
        setPage(page)
        var ofs = (page - 1) * 10;

        getUsers(ofs, userId.value, searchEmail, searchPhone, searchGender.value, searchCountry.value).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };


    // searching functionality
    const handleSearch = () => {
        setListLoading(true);
        getUsers(0, userId.value, searchEmail, searchPhone, searchGender.value, searchCountry.value).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res?.data);
                setTotalRows(res?.totalCount);
            }
        })
    }

    // input box clear functionality
    const handleClear = () => {
        uniqueCountries();
        handlePerRowChange(1);
        setUserId({ value: "", label: "Search User" });
        setSearchEmail("");
        setSearchGender({ value: "", label: "Select Gender" });
        setSearchCountry({ value: "", label: "Select Country" });
        setSearchPhone("");
    }


    // gender arrayt
    const Gender = [
        {
            value: "male",
            label: "Male"
        }, {
            value: "female",
            label: "Female"
        }, {
            value: "other",
            label: "Other"
        }
    ]

    return (
        <>
            <AppTitle title={"App Users"} />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={userId}
                                            onChange={
                                                (value) => {
                                                    setUserId(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>Email</CFormLabel>
                                        <input type="text" placeholder="Search Email"
                                            value={searchEmail}
                                            onChange={
                                                (e) => {
                                                    setSearchEmail(e.target.value)
                                                }
                                            }
                                            className="form-control" />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>Phone</CFormLabel>
                                        <input type="text" placeholder="Search Phone"
                                            value={searchPhone}
                                            onChange={
                                                (e) => {
                                                    setSearchPhone(e.target.value)
                                                }
                                            }
                                            className="form-control" />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}
                                        className="mt-md-4">
                                        <CFormLabel>Gender</CFormLabel>
                                        <Select placeholder="Select User"
                                            options={Gender}
                                            value={searchGender}
                                            onChange={
                                                (value) => {
                                                    setSearchGender(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}
                                        className="mt-md-4">
                                        <CFormLabel>Country</CFormLabel>
                                        {/* <CFormSelect name="" id=""
                            value={
                                searchCountry || ""
                            }
                            onChange={
                                (e) => {
                                    setSearchCountry(e.target.value)
                                }
                            }
                            className="form-control">
                            <option value=""
                                style={
                                    {fontSize: "14px"}
                            }>Select Country</option>
                            {
                            contries ?. map((country) => {
                                return <option value={
                                        country ?. _id
                                    }
                                    style={
                                        {fontSize: "14px"}
                                }>
                                    {
                                    country ?. countryName
                                }</option>
                        })
                        } </CFormSelect> */}
                                        <Select placeholder="Select Country"
                                            options={contries}
                                            value={searchCountry}
                                            onChange={
                                                (value) => {
                                                    setSearchCountry(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol xs={12}
                                        className="mt-4">
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    () => handleSearch()
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol></CRow><div>
                    <CCard>
                        <CCardHeader>
                            <strong>Users</strong>
                        </CCardHeader>
                        <CCardBody> {
                            listLoading ? <div className="d-flex justify-content-center"><CSpinner style={
                                { marginTop: "40px" }
                            }
                                color="info" /></div> : <DataTable columns={columns}
                                    data={data}
                                    style={
                                        { border: '1px solid black' }
                                    }
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    progressPending={listLoading}
                                    paginationRowsPerPageOptions={
                                        []
                                    }
                                    paginationComponentOptions={paginationComponentOptions}
                                    paginationTotalRows={totalRows}
                                    customStyles={customStyles}
                                    onChangePage={handlePageChange} />
                        } </CCardBody>
                    </CCard></div></div></>
    );
}

export default Appusers;
