import React, {useEffect, useState} from 'react'
import AppTitle from '../AppTitle'
import strings from '../strings'
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CContainer,
    CRow,
    CSpinner
} from '@coreui/react'
import {GetSingleSubscriptionPlan} from '../Helper/Index.Api'
import {useParams} from 'react-router-dom'
import {FormLabel} from 'react-bootstrap'
import "./subscription.css"
const moment = require("moment");const SubsribedUserDetails = () => {
const [data, setData] = useState({});
const {id} = useParams();
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
    // sinlge subscription plan
    GetSingleSubscriptionPlan(id).then((res) => {
        setData(res.data[0])
        setIsLoading(false);
    }).catch(err => {
        setIsLoading(false)
        console.log(err)
    })
}, [])


return (
    <>
        <AppTitle title={"Subscription Details"}
            showbtn={true}
            path={"/subscribedusers"}/> {
        isLoading ? <div className='d-flex justify-content-center align-items-center'>
            <CSpinner style={
                    {marginTop: "80px"}
                }
                color="info"/>
        </div> : <CContainer className='px-lg-5 mt-lg-5 px-2 mt-2'>
            <CCard>
                <CCardHeader>Subscription Details</CCardHeader>
                <CCardBody>
                    <CRow>
                        <div className='d-flex justify-content-start'>
                            <img className='rounded-circle'
                                style={
                                    {
                                        width: "100px",
                                        height: "100px"
                                    }
                                }
                                src={
                                    data ?. userId ?. userAvatar ?. cdnlocation ? strings ?. MEDIAPATH + data ?. userId ?. userAvatar ?. cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                }
                                alt="iamge"/>
                        </div>
                    </CRow>
                    <CRow>
                        <div className='d-flex justify-content-start'>
                            <p style={
                                {fontSize: "20px",fontWeight:600}
                            }>
                                {
                                data ?. userId ?. first_Name.toUpperCase()
                            }
                                {"  "}
                                {
                                data ?. userId ?. last_Name.toUpperCase()
                            }</p>
                        </div>
                    </CRow>
                    <CRow>
                        <div>
                            <label for="exampleFormControlInput1" className="form-label label">Membership Plan</label><br/>
                            <input type="email" className="form-control inputbox"
                                style={
                                    {borderRadius: "none"}
                                }
                                id="exampleFormControlInput1"
                                value={
                                    data ?. planTitle
                                }/>
                        </div>
                    </CRow><br/>
                    <CRow>
                        <div>
                            <label for="exampleFormControlInput1" className="form-label label">Subscription Date</label><br/>
                            <input type="email" className="form-control inputbox"
                                style={
                                    {borderRadius: "none"}
                                }
                                id="exampleFormControlInput1"
                                value={
                                    moment(data ?. entryDate).format("DD-MMM-YYYY")
                                }/>
                        </div>
                    </CRow><br/>
                    <CRow>
                        <div>
                            <label for="exampleFormControlInput1" className="form-label label">Price</label><br/>
                            <input type="email" className="form-control inputbox"
                                style={
                                    {borderRadius: "none"}
                                }
                                id="exampleFormControlInput1"
                                value={
                                    data ?. planId ?. sell_price
                                }/>
                        </div>
                    </CRow><br/>
                    <CRow>
                        <div>
                            <label for="exampleFormControlInput1" className="form-label label">Duration</label><br/>
                            <input type="email" className="form-control inputbox"
                                style={
                                    {borderRadius: "none"}
                                }
                                id="exampleFormControlInput1"
                                value={
                                    data ?. planId ?. duration + " Months"
                                }/>
                        </div>
                    </CRow><br/>
                    <CRow>
                        <div>
                            <label for="exampleFormControlInput1" className="form-label label">Expiry Date</label><br/>
                            <input type="email" className="form-control inputbox"
                                style={
                                    {borderRadius: "none"}
                                }
                                id="exampleFormControlInput1"
                                value={
                                    moment(data ?. expDate).format("DD-MMM-YYYY")
                                }/>
                        </div>
                    </CRow>
                </CCardBody>
            </CCard>
        </CContainer>
    } </>
)};export default SubsribedUserDetails
