import React, { useEffect, useState } from 'react'
import AppTitle from '../AppTitle'
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormLabel,
    CFormSelect,
    CRow,
    CSpinner
} from '@coreui/react'
import DataTable from 'react-data-table-component'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import { GetCommunityList, communityPageUsers } from "../Helper/Index.Api";
import strings from "../strings";
import * as icon from '@coreui/icons';
import Select from 'react-select'
import moment from 'moment'

const Communitypages = () => {
    const [data, setData] = useState();
    const [listLoading, setListLoading] = useState();
    const [totalRows, setTotalRows] = useState();
    const [page, setPage] = useState(1);
    const [searchName, setSearchName] = useState({ value: "", label: "Search User" });
    const [searchTitle, setSearchTitle] = useState("");
    const [users, setUsers] = useState([]);
    const [Options, setOptins] = useState([]);

    const [openDrawer, setOpenDrawer] = useState(false);
    const handleToggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const styleheader = {
        marginTop: "-24px",
        marginLeft: "-25px",
        marginRight: "-25px"
    }


    useEffect(() => {

        // community page users
        communityPageUsers().then((res) => {
            setUsers(res.data);
            return res;
        }).then((res) => {
            let selectOptins = res?.data?.map((el) => {
                return {
                    value: el._id, label: `${el.first_Name + " " + el.last_Name + " "
                        }(${el?.email
                        })`
                }
            });
            setOptins(selectOptins)
        }).catch(err => console.log(err))
    }, [])


    const customStyles = {
        headRow: {
            style: {
                backgroundColor: "#5470af",
                color: "white"
            }
        },
        cells: {
            style: {
                borderRight: "1px solid #ddd",
                borderLeft: "1px solid #ddd"
            }
        }
    };

    useEffect(() => {
        handlePerRowChange(1)
    }, []);

    const columns = [
        {
            name: 'Sr. No.',
            selector: (row, index) => page * 10 + index + 1 - 10,
            width: "70px"
        },
        {
            name: 'Logo',
            selector: row => (
                <>
                    <div className="">
                        <img className=" rounded-circle"
                            style={
                                {
                                    width: "40px",
                                    height: "40px"
                                }
                            }
                            src={
                                row.logoData?.cdnlocation ? strings.MEDIAPATH + row?.logoData?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                            } />
                    </div>
                </>
            ),
            width: "75px"
        },
        {
            name: 'Title',
            selector: row => (`${row?.title
                }`)
        },
        {
            name: 'Created By',
            selector: row => (row?.createdBy?.first_Name + " " + row?.createdBy?.last_Name),
            // width: "150px"
        }, {
            name: 'Country',
            selector: row => (row?.countryId ? row?.countryId?.countryName : "-")
        }, {
            name: 'City',
            selector: row => (row?.city)
        }, {
            name: 'Email',
            selector: row => (row?.createdBy?.email),
            // width: "150px"
        },
        {
            name: "Created At",
            selector: row => <div>{moment(row.createdAt).format("MMM DD YYYY")}</div>
        },
        {
            name: 'Actions',
            selector: row => (
                <>
                    <Link to={
                        `/communitypage/${row?._id
                        }`
                    }
                        className="p-1">
                        <CButton color="secondary" title="View User" variant="outline" className="ps-1 px-1">
                            <CIcon icon={
                                icon.cilFolderOpen
                            }
                                size="lg" />
                        </CButton>

                    </Link>
                </>
            ),
            width: "120px"
        },
    ];

    // row change
    const handlePerRowChange = (page) => {
        if (page === undefined) {
            page = 0;
        }
        setPage(page)
        var ofs = (page - 1) * 10;
        setListLoading(true);

        //getting community page list

        GetCommunityList(ofs, "", "").then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true
    };


    // pagination datatable
    const handlePageChange = (page) => {
        if (page === undefined) {
            page = 1;
        }
        var ofs = (page - 1) * 10;

        GetCommunityList(ofs, searchName.value, searchTitle).then((res) => {
            setListLoading(false);
            console.log("res176:", res)
            if (res.err === 200) {

                setData(res.data);
                setTotalRows(res.totalCount);
            } else {
                setListLoading(false);
            }
        }).catch(() => setListLoading(false));
    };

    // search functionality
    const handleSearch = () => {
        setListLoading(true);
        GetCommunityList(0, searchName.value, searchTitle).then((res) => {
            setListLoading(false);
            if (res.err === 200) {
                setData(res.data);
                setTotalRows(res.data.length);
            }
        })
    }

    // clear search input boxes
    const handleClear = () => {
        handlePerRowChange(1);
        setSearchName({ value: "", label: "Search User" });
        setSearchTitle("");
    }


    return (
        <>
            <AppTitle title={"Community Pages"} />
            <div className="px-lg-5 mt-lg-5 px-2 mt-2">
                <CRow className="justify-content-center mb-3">
                    <CCol md={12}
                        lg={12}>
                        <CCard>
                            <CCardHeader>
                                <strong>Filter:</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>Title</CFormLabel>
                                        <input type="text" placeholder="Search Title"
                                            value={
                                                searchTitle || ""
                                            }
                                            onChange={
                                                (e) => {
                                                    setSearchTitle(e.target.value)
                                                }
                                            }
                                            className="form-control" />
                                    </CCol>
                                    <CCol md={4}
                                        sm={12}>
                                        <CFormLabel>User Name</CFormLabel>
                                        {/* <input type="text" placeholder="Search User"
                                        value={
                                            searchName || ""
                                        }
                                        onChange={
                                            (e) => {
                                                setSearchName(e.target.value)
                                            }
                                        }
                                        className="form-control"/> */}
                                        {/* <CFormSelect size="md"
                                        style={
                                            {fontSize: "16px"}
                                        }
                                        value={
                                            searchName || ""
                                        }
                                        onChange={
                                            (e) => {
                                                setSearchName(e.target.value)
                                            }
                                        }
                                        className="mb-3"
                                        aria-label="Large select example">
                                        <option style={
                                                {fontSize: "14px"}
                                            }
                                            value={""}>Select user</option>
                                        {
                                        users ?. map((el) => {
                                            return <option style={
                                                    {fontSize: "14px"}
                                                }
                                                value={
                                                    el._id + ""
                                            }>
                                                {
                                                el ?. first_Name + " " + el ?. last_Name + " "
                                            }
                                                <span style={
                                                    {marginLeft: "20px"}
                                                }>({
                                                    "  " + el.email
                                                })</span>
                                            </option>
                                    })
                                    } </CFormSelect> */}
                                        <Select placeholder="Select User"
                                            options={Options}
                                            value={searchName}
                                            onChange={
                                                (value) => {
                                                    setSearchName(value)
                                                }
                                            } />
                                    </CCol>
                                    <CCol xs={12}
                                        className="mt-4">
                                        <div>
                                            <CButton style={
                                                {
                                                    backgroundColor: "#5470af",
                                                    border: "none"
                                                }
                                            }
                                                className="m-2 text-white"
                                                onClick={
                                                    () => handleSearch()
                                                }
                                                type="button">
                                                Search
                                            </CButton>
                                            <CButton color="dark" type="submit" className="text-white"
                                                onClick={
                                                    () => {
                                                        handleClear();
                                                    }
                                                }>
                                                Clear
                                            </CButton>
                                        </div>
                                    </CCol>
                                </CRow>

                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CCard>
                    <CCardHeader>
                        <strong>Community Pages</strong>
                    </CCardHeader>
                    <CCardBody> {
                        listLoading ? <div className="d-flex justify-content-center"><CSpinner style={
                            { marginTop: "40px" }
                        }
                            color="info" /></div> : <DataTable columns={columns}
                                data={data}
                                style={
                                    { border: '1px solid black' }
                                }
                                highlightOnHover
                                pagination
                                paginationServer
                                progressPending={listLoading}
                                paginationRowsPerPageOptions={
                                    []
                                }
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalRows}
                                customStyles={customStyles}
                                onChangePage={handlePageChange} />
                    } </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default Communitypages
