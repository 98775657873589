import React from "react";

import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import avatar8 from "./../../assets/images/avatars/8.jpg";
import swal from "sweetalert";
import strings from "../../components/strings";

const ADMINPROFILE = strings

const AppHeaderDropdown = () => {
  const profile = localStorage.getItem("profile");
  const userName = localStorage.getItem("userName")


  let navigate = useNavigate();
  const logout = async () => {
    localStorage.clear();
    navigate("/login");
  };



  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar size="lg">
          <img className="rounded-circle" style={{ width: "50px", height: "50px" }} src={strings.MEDIAPATH + profile} alt="user-avatar" />
        </CAvatar>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          {userName || "Admin"}
        </CDropdownHeader>

        <Link to="/profilepage" className="text-decoration-none">
          <CDropdownItem>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p><CIcon icon={cilUser} className="me-1" /></p>
              <p>My Profile</p>
            </div>
          </CDropdownItem>
        </Link>


        <CDropdownDivider />
        <CDropdownItem href="#" onClick={logout}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p><CIcon icon={cilLockLocked} className="me-1" /></p>
            <p>Logout</p>
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
