import React, { useEffect, useState } from 'react'
import { followersList, followingList, viewUser } from '../Helper/Index.Api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./dashbord.css";

import {
    CContainer,
    CRow,
    CCol,
    CCard,
    CSpinner,
    CNav,
    CNavItem,
    CNavLink,
} from "@coreui/react";
import strings from '../strings';
import { cilSpeedometer } from '@coreui/icons';
import SingleUserPosts from './SingleUserPosts';
import FollowersModel from './FollowersModel';
import Followers from './Followers';
import Followings from './Followings';
import AppTitle from '../AppTitle';


const ViewUser = () => {
    let navigate = useNavigate();
    let { id } = useParams();
    const [data, setData] = useState();
    const [followersCount, setFollowersCount] = useState();
    const [followingsCount, setFollowingsCount] = useState();
    const [followersData, setFollowersData] = useState();
    const [followingsData, setFollowingsData] = useState();
    const [showModal, setShowModel] = useState();
    useEffect(() => {

        // getting user details
        viewUser({ _id: id }).then((res) => {
            if (res.err === 200) {
                setData(res.data);
                console.log(res.data);
            }
        })

        // get user follower list
        followersList({ userId: id }).then((res) => {

            if (res.err === 200) {
                setFollowersCount(res.count)
                setFollowersData(res.data)
            }
        })
        followingList({ userId: id }).then((res) => {
            if (res.err === 200) {
                setFollowingsCount(res.count)
                setFollowingsData(res.data)
            }
        })
    }, []);

    const handleFollowers = () => {
        setShowModel(true)
    }
    return (

        <>
            <AppTitle title={"User Details"} path={`/appusers`} showbtn={true} />
            <CContainer className='px-lg-5 mt-lg-5 px-2 mt-2'>
                <CRow>
                    {
                        !data ? (

                            <>
                                <div className='text-center mt-5'>
                                    <CSpinner color='info' />
                                </div>

                            </>

                        ) : (
                            <CCol md={12} lg={12} sm={12}>
                                <CCard style={{ borderRadius: "5px 5px 0px 0px" }}>
                                    <CRow>
                                        <CCol sm={12}>
                                            <div style={{ backgroundColor: "rgb(79 113 189 / 39%)", height: "150px", borderRadius: "5px 5px 0px 0px" }}></div>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    top: "75px",
                                                    left: "25px"
                                                }}>
                                                <img
                                                    src={data?.userAvatar?.cdnlocation !== "" ? `${strings.MEDIAPATH}${data?.userAvatar?.cdnlocation
                                                        }` : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"}
                                                    style={{ width: "150px", height: "150px", }}
                                                    className='rounded-circle border border-white border-5'
                                                    alt=""
                                                />
                                                <div className='ms-1'>
                                                    {console.log("DATA:", data)}
                                                    <h4>
                                                        {data?.first_Name?.charAt(0).toUpperCase() + data?.first_Name?.slice(1)} {data?.last_Name}
                                                    </h4>
                                                    <small className='pb-3'>
                                                        <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}{data?.city} {data?.state}, {data?.country} <br />
                                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>  {data?.email}<br />
                                                    </small>
                                                    {data?.phonenumber && <small>
                                                        <i class="fa fa-phone" aria-hidden="true"></i>{" "}{data?.phonenumber}<br />
                                                    </small>}
                                                    {data?.universityORcompany && <small>
                                                        <i class="fa fa-university" aria-hidden="true"></i>{" "}{data?.universityORcompany}<br />
                                                    </small>}
                                                    {data?.gender && <small>
                                                        <i class="fa fa-user" aria-hidden="true"></i>{" "}{data?.gender}<br />
                                                    </small>}
                                                    {data?.catchLine && <small className='text-secondary me-2'>
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>{data?.catchLine.length > 300 ? data?.catchLine?.slice(0, 300) + "..." : data?.catchLine}
                                                    </small>}
                                                </div>
                                            </div>
                                            <div className='d-flex mt-2'>
                                                <button
                                                    style={{
                                                        position: "absolute",
                                                        top: "160px",
                                                        right: "22px",
                                                        backgroundColor: "rgb(79 113 189 / 39%)",
                                                        padding: "6px 20px",
                                                        borderRadius: "17px",
                                                        border: "0",
                                                    }}
                                                    onClick={() => { navigate("/messages/personal/" + id) }}
                                                >
                                                    Chats
                                                </button>
                                                <button
                                                    style={{
                                                        position: "absolute",
                                                        top: "160px",
                                                        right: "112px",
                                                        backgroundColor: "rgb(79 113 189 / 39%)",
                                                        padding: "6px 20px",
                                                        borderRadius: "17px",
                                                        border: "0",
                                                    }}
                                                    onClick={() => { navigate("/messages/groups/" + id) }}
                                                >
                                                    Group Chats
                                                </button>
                                            </div>
                                            <div style={{ backgroundColor: "white", height: "280px", borderRadius: " 0px 0px 0px 0px" }}>
                                                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist" style={{
                                                    position: "absolute",
                                                    bottom: "17px",
                                                    left: "23px"
                                                }}>
                                                    <hr />
                                                </ul>
                                            </div>
                                            <div className='px-4'>
                                                <CNav variant="tabs">
                                                    <CNavItem>
                                                        <CNavLink active id="pills-profile-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-profile"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-profile"
                                                            aria-selected="true">
                                                            <span style={{ fontSize: "18px", fontWeight: "700" }}>{followersCount}</span>{" "}  Followers
                                                        </CNavLink>
                                                    </CNavItem>
                                                    <CNavItem >
                                                        <CNavLink id="pills-contact-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-contact"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-contact"
                                                            aria-selected="false"><span style={{ fontSize: "18px", fontWeight: "700" }}>{followingsCount}</span>{" "}  Followings</CNavLink>
                                                    </CNavItem>
                                                </CNav>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCard>

                                <div class="tab-content" id="pills-tabContent">
                                    {/* <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <SingleUserPosts />
                                    </div> */}
                                    <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <Followers followersData={followersData} />
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <Followings followingsData={followingsData} />
                                    </div>
                                </div>

                            </CCol>
                        )
                    }

                </CRow>
            </CContainer>
        </>


    )
}

export default ViewUser;